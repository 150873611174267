@import "../../../styles/vars";
.verticals__list .flickity-button {
  display: none !important;
}
.verticals__item {
  margin-right: 16px;
  border: 2px solid $main-color;
  border-radius: 8px;
  padding: 24px 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 342px;
  height: 360px;
  @media screen and (max-width: 389px) {
    padding: 18px 24px 32px;
    width: 270px;
    height: 284px;
  }
}
.verticals__title {
  color: $accent-color;
  font-size: 28px;
  line-height: 1;
}
.verticals__date {
  margin-top: 16px;
  color: $secondary-color;
}
.verticals__img-box {
  margin-top: auto;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 389px) {
    height: 130px;
  }
}
.verticals__icon {
  display: block;
  width: 180px;
  height: 180px;
  @media screen and (max-width: 389px) {
    width: 130px;
    height: 130px;
  }
}
@media screen and (min-width: 820px) {
  .verticals {
    will-change: transform;
    scroll-behavior: smooth
  }
  .verticals__list {
    will-change: transform;
    margin-left: $margin-left-s;
    height: 100%;
    display: inline-flex;
    align-items: flex-start;
  }
  .verticals__item {
    border: none;
    padding: 0;
    flex-direction: column-reverse;
    justify-content: left;
    gap: 16px;
    width: 170px;
    height: auto;
  }
  .verticals__title {
    font-size: 28px;
    color: $main-color;
  }
  .verticals__date {
    display: none;
  }
  .verticals__img-box {
    border: 2px solid $main-color;
    border-radius: 8px;
    width: 170px;
    &.even {
      height: 120px;
    }
    &.odd {
      height: 170px;
    }
  }
  .verticals__icon {
    &.even {
      width: 142px;
      height: 78px;
    }
    &.odd {
      width: 127.5px;
      height: 127.5px;
    }

  }
}
@media screen and (min-width: 1024px) {
  .verticals__list {
    margin-left: $margin-left-m;
  }
}
@media screen and (min-width: 1440px) {
  .verticals__list {
    margin-left: $margin-left-l;
  }
  .verticals__item {
    width: 240px;
  }
  .verticals__title {
    font-size: 40px;
  }
  .verticals__img-box {
    width: 240px;
    &.even {
      height: 170px;
    }
    &.odd {
      height: 240px;
    }
  }
  .verticals__icon {
    &.even {
      width: 200px;
      height: 110px;
    }
    &.odd {
      width: 180px;
      height: 180px;
    }

  }
}
@media screen and (min-width: 1920px) {
  .verticals__list {
    margin-left: $margin-left-xl;
  }
  .verticals__item {
    width: 320px;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
  .verticals__title {
    font-size: 53px;
  }
  .verticals__img-box {
    width: 320px;
    &.even {
      height: 226px;
    }
    &.odd {
      height: 320px;
    }
  }
  .verticals__icon {
    &.even {
      width: 266px;
      height: 146px;
    }
    &.odd {
      width: 240px;
      height: 240px;
    }
  }
}