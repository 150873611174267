@import "../../../styles/vars";

.options__number-list {
  display: none;
}

.options__list {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  text-transform: uppercase;
  &--do {
    gap: 24px;
    font-size: 28px;
    line-height: 1;
  }
}

.options__label {
  font-size: 28px;
  line-height: 1;
}

.options__item {
  padding: 16px 0;

  &:not(:first-child) {
    border-top: 1px solid $divider-color;
  }
}

@media screen and (min-width: 820px) {
  .options__wrapper {
    padding: 0 24px;
    display: grid;
    grid-template-columns: 90px 3fr 2fr;
    gap: 75px;
    justify-content: left;
    align-items: start;
  }
  .options__number-list {
    margin-top: 164px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .options__number-item {
    font-size: 28px;
    line-height: 1;
    &:first-child {
      padding-bottom: 56px;
    }
    &:nth-child(2) {
      padding-bottom:28px;
    }
  }
  .options__list--do {
    margin-top: 52px;
    gap: 16px;
    li {
      font-size: 28px;
    }
  }
  .options__list--get{
    margin-top: 100px;
    & .options__item {
      padding: 8px 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
@media screen and (min-width: 1024px){
 .options__wrapper {
   padding: 0 84px;
   grid-template-columns: 93px 3fr 2fr;
   gap: 125px;
 }
}
@media screen and (min-width: 1440px) {
  .options__wrapper {
    padding: 0 120px;
    grid-template-columns: 136px 3fr 2fr;
    gap: 168px;
  }
  .options__number-list {
    margin-top: 244px;
    gap: 24px;
  }
  .options__number-item {
    font-size: 40px;
    &:first-child {
      padding-bottom: 80px;
    }
    &:nth-child(2) {
      padding-bottom: 40px;
    }
  }
  .options__list--do {
    gap: 24px;
    li {
      font-size: 40px;
    }
  }
  .options__label {
    font-size: 40px;
  }
  .options__list--get{
    margin-top: 156px;
    & .options__item {
      padding: 8px 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .options__wrapper {
    padding: 0 160px;
    grid-template-columns: 179px 3fr 2fr;
    gap: 225px;
  }
  .options__number-list {
    margin-top: 296px;
    gap: 32px;
  }
  .options__number-item {
    font-size: 54px;
    &:first-child {
      padding-bottom: 108px;
    }
    &:nth-child(2) {
      padding-bottom: 54px;
    }
  }
  .options__list--do {
    margin-top: 84px;
    width: 585px;
    gap: 32px;
    li {
      font-size: 54px;
    }
  }
  .options__label {
    font-size: 54px;
  }
  .options__list--get{
    margin-top: 164px;
    & .options__item {
      padding: 24px 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}