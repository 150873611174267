@import "../../../styles/vars";
.main-cases__link {
  display: inline-block;
  margin-top: 24px;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.main-cases__list {
  display: none;
}
.main-cases__list--mob {
  margin: 0 auto 64px;
  padding: 0 24px;
  display: grid;
  gap: 30px;
  max-width: 390px;

.main-cases__item {
  padding: 16px;
  border: 1px solid $main-color;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.main-cases__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.main-cases__geodate-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.main-cases__vertical {
  font-size: 28px;
  line-height: 1;
}
.main-cases__data {
  color: $secondary-color;
}
}
.line {
  width: 100%;
  height: 1px;
  background-color: $divider-color;
}
@media screen and (min-width: 820px) {
  .main-cases__list {
    display: block;
  }
  .main-cases__box {
    padding-left: $margin-left-s !important;
    padding-right: 24px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 75px;
    align-items: start;
  }
  .main-cases__link {
    margin: 0;
    color: $accent-color;
  }
  .main-cases__list--mob {
    display: none;
  }
  .main-cases__item {
    position: relative;
    display: grid;
    grid-template-columns: 75px 90px 182px 181px 1fr;
    gap: 5px;
    &:not(:first-child) {
      border-bottom: 1px solid $secondary-color;
      padding: 8px 12px;
      height: 76px;
      overflow: hidden;
      transition: height .5s ease-in-out;
      &:hover {
        height: 190px;
      }
    }
    &:first-child {
      padding: 0 12px 24px;
    }
  }
  .main-cases__data {
    color: $secondary-color;
  }
  .main-cases__result {
    color: $accent-color;
  }
  .main-cases__gradient {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16px;
    background: linear-gradient(0, $bg-color 0%, $bg-color 20%, #0F0F0C00);
  }
}
@media screen and (min-width: 1024px) {
  .main-cases__box {
    padding-left: $margin-left-m !important;
    padding-right: 84px;
  }
  .main-cases__list {
    margin: 0 12px;
  }
  .main-cases__item {
    grid-template-columns: 1fr 1fr 2.85fr 1fr 2.15fr;
    &:not(:first-child) {
      padding: 8px 72px;
      &:hover {
        height: 160px;
      }
    }
    &:first-child {
      padding: 0 72px 24px;
    }
  }
}
@media screen and (min-width: 1440px) {
  .main-cases__box {
    padding-left: $margin-left-l !important;
    padding-right: 120px;
  }
  .main-cases__item {
    grid-template-columns: 1fr 1fr 2.8fr 1fr 2.2fr;

    &:not(:first-child) {
      padding: 16px 104px;
      height: 108px;
      &:hover {
        height: 224px;
      }
    }
    &:first-child {
      padding: 0 104px 16px;

    }
  }
  .main-cases__gradient {
    height: 24px;
  }
}
@media screen and (min-width: 1920px) {
  .main-cases__box {
    padding-left: $margin-left-xl !important;
    padding-right: 160px;
  }
  .main-cases__item {
    grid-template-columns: 1fr 1fr 2.7fr 1fr 2.3fr;

    &:not(:first-child) {
      padding: 24px 148px;
      height: 140px;
      &:hover {
        height: 300px;
      }
    }
    &:first-child {
      padding: 0 148px 16px;
    }
  }
  .main-cases__gradient {
    height: 24px;
  }
}