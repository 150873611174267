@import "../../../styles/vars";

.map__box {
  position: relative;
  margin: 0 auto;
  max-width: 342px;
}

.map__img {
  width: 100%;
}

.map__point {
  position: absolute;
  display: block;
  width: 26px;
  height: 28px;
  background-size: contain;
  transition: all 250ms ease-in-out;
  .map__item.isHidden & {
    opacity: 0;
    transform: translateY(-200%);
  }
  &--1 {
    top: 9%;
    left: 18.5%;
    background-image: url('/images/map/map-item-1.svg');
  }

  &--2 {
    top: 21.5%;
    left: 27%;
    background-image: url('/images/map/map-item-2.svg');
  }

  &--3 {
    top: 9.5%;
    left: 46.5%;
    background-image: url('/images/map/map-item-3.svg');
  }

  &--4 {
    top: 19.5%;
    left: 47%;
    background-image: url('/images/map/map-item-4.svg');
  }

  &--5 {
    top: 7.5%;
    left: 61%;
    background-image: url('/images/map/map-item-5.svg');
  }

  &--6 {
    top: 11.5%;
    left: 72.5%;
    background-image: url('/images/map/map-item-6.svg');
  }

  &--7 {
    top: 24%;
    left: 80.5%;
    background-image: url('/images/map/map-item-7.svg');
  }
}

@media screen and (max-width: 819px) {
  .map__list {
    margin-top: 24px;
    padding: 0 24px;
  }
  .map__item {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      border-top: 1px solid $divider-color;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
    }
  }
}

@media screen and (min-width: 820px) {
  .map {
    padding-bottom: 84px;
  }
  .map__container {
    padding-left: $margin-left-s !important;
  }
  .map__box {
    max-width: 768px;
  }
  .map__item {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 93.75px;
    height: 97.5px;
    background-size: cover;
    font-size: 12px;
    text-align: center;
    transition: all 700ms ease-in-out;
    &.isHidden {
      opacity: 0;
      transform: translateY(-250%);
    }
    &--1 {
      top: 19%;
      left: 10.5%;
      background-image: url('/images/map/map-item-1-tab.svg');
    }

    &--2 {
      top: 52%;
      left: 25%;
      background-image: url('/images/map/map-item-2-tab.svg');
      & span {
        width: 90%;
      }
    }

    &--3 {
      top: 19%;
      left: 41.5%;
      background-image: url('/images/map/map-item-3-tab.svg');
    }

    &--4 {
      top: 51.5%;
      left: 45.5%;
      background-image: url('/images/map/map-item-4-tab.svg');
    }

    &--5 {
      top: 10%;
      left: 56%;
      background-image: url('/images/map/map-item-5-tab.svg');
    }

    &--6 {
      top: 27%;
      left: 68%;
      background-image: url('/images/map/map-item-6-tab.svg');
    }

    &--7 {
      top: 62%;
      left: 77.5%;
      background-image: url('/images/map/map-item-7-tab.svg');
    }
  }
  .map__point {
    display: none
  }
}

@media screen and (min-width: 1024px) {
  .map__container {
    padding-left: $margin-left-m !important;
  }
  .map__box {
    max-width: 1000px;
  }
  .map__item {
    width: 125px;
    height: 130px;
    font-size: inherit;
  }
}
@media screen and (min-width: 1440px) {
  .map__container {
    padding-left: $margin-left-l !important;
  }
  .map {
    padding-bottom: 120px;
  }
  .map__box {
    max-width: 1408px;
  }
  .map__item {
    width: 176px;
    height: 183px;
  }
}
@media screen and (min-width: 1920px) {
  .map__container {
    padding-left: $margin-left-xl !important;
  }
  .map {
    padding-bottom: 160px;
  }
  .map__box {
    max-width: 1880px;
  }
  .map__item {
    width: 235px;
    height: 244px;
  }
}