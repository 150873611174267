@import "../../../styles/vars";

.verticals {
  padding-bottom: 64px;
}
.verticals__text {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
@media screen and (min-width: 820px) {
  .pin-spacer {
    position: relative;
  }

  .verticals__container {
    padding-left: $margin-left-s !important;
    padding-right: 24px;
    display: grid;
    grid-template-columns: 3fr 2fr ;
    gap: 75px;
    text-align: left;
  }
  .verticals__text {
    margin-top: 0;
    flex-direction: row-reverse;
    justify-content: left;
    width: 202px;
    gap: 0
  }
}
@media screen and (min-width: 1024px) {
  .verticals__container {
    padding-left: $margin-left-m !important;
    padding-right: 84px;
    gap: 125px;
  }
}
@media screen and (min-width: 1440px) {
  .verticals__container {
    padding-left: $margin-left-l !important;
    padding-right: 120px;
    grid-template-columns: 440px 1fr ;
    gap: 168px;
  }
  .verticals__text {
    width: 288px;
  }
}
@media screen and (min-width: 1920px) {
  .verticals__container {
    padding-left: $margin-left-xl !important;
    padding-right: 160px;
    grid-template-columns: 585px 1fr ;
    gap: 225px;
  }
  .verticals__text {
    width: 368px;
  }
}